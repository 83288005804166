<template lang="pug">
  h5.total-item
    span.text-label Total:&nbsp;
    span {{ getAmountTotal }} ({{ '+' + getDifferenceTotal }})
</template>

<script>
export default {
  name: 'CbrUpdatesTotalItem',

  props: {
    items: Array
  },

  computed: {
    getAmountTotal() {
      return this.items.reduce(function (sum, current) {
        return sum + current.total_amount;
      }, 0);
    },

    getDifferenceTotal() {
      return this.items.reduce(function (sum, current) {
        return sum + current.difference;
      }, 0);
    }
  }
}
</script>

<style lang="scss" scoped>
.total-item {
  padding-top: 15px;
}
</style>